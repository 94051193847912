// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-en-nutrition-blog-index-js": () => import("./../../../src/pages/en/nutrition-blog/index.js" /* webpackChunkName: "component---src-pages-en-nutrition-blog-index-js" */),
  "component---src-pages-en-privacy-js": () => import("./../../../src/pages/en/privacy.js" /* webpackChunkName: "component---src-pages-en-privacy-js" */),
  "component---src-pages-es-privacidad-js": () => import("./../../../src/pages/es/privacidad.js" /* webpackChunkName: "component---src-pages-es-privacidad-js" */),
  "component---src-pages-index-es-js": () => import("./../../../src/pages/index_es.js" /* webpackChunkName: "component---src-pages-index-es-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-blog-post-es-js": () => import("./../../../src/templates/blog-post_es.js" /* webpackChunkName: "component---src-templates-blog-post-es-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

